import React from 'react'
import { Helmet } from 'react-helmet'
import { CheckoutProvider } from '@contexts'
import { Checkout, Container } from '@components'

import { DefaultLayout } from '../layouts/defaultlayout'

// eslint-disable-next-line import/no-default-export
export default () => (
  <DefaultLayout isDataLoaded>
    <Helmet>
      <title>Hell-O-Chili - Checkout</title>
      <script src="https://www.paypalobjects.com/api/checkout.js" />
    </Helmet>
    <CheckoutProvider>
      <Container fullHeight>
        <Checkout />
      </Container>
    </CheckoutProvider>
  </DefaultLayout>
)
